//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { bgimgUrl2 } from '/src/utils/config.js';	
export default {
	name: "diploma",
	data() {
		return {
			img: '',
			bgimgUrl2:''
		}
	},
	mounted() {
		this.img = this.$route.query.imgUrl;
		this.bgimgUrl2 = bgimgUrl2;
	},
	methods: {

	}
}
